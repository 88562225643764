import { format, isDate } from "date-fns";

export const shortDate = (date: number | Date) => {
  if (!date) date = new Date();
  return format(date, "d MMM yyyy");
};

export const shortTime = (date: number | Date) => {
  if (!date) date = new Date();
  return format(date, "h:mm aa");
};

export const escapeDate = (value: number | Date) => {
  if (isDate(value)) return shortDate(value);
  return value;
};

export const formatPercentage = (value: any) => {
  if (!value) return;
  return `${value}%`;
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) return text.substring(0, maxLength) + "...";

  return text;
};

export const formatEntry = (entry: { event: { [x: string]: any } }): any => {
  return Object.keys(entry.event)
    .filter(
      (key) =>
        typeof entry.event[key] === "string" || entry.event[key]?.entry?.event
    )
    .map((key) =>
      entry.event[key]?.entry?.event
        ? `${key}: { ${formatEntry(entry.event[key]?.entry)} }`
        : `${key}: ${String(entry.event[key])}`
    )
    .join(", ");
};

export const formatNumberWithCommas = (number: number): string => {
  if (!number) return "0";
  let numberString = number.toString();
  let parts = numberString.split(".");
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? "." + parts[1] : "";
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedIntegerPart + decimalPart;
};
